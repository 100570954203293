$base-font-size: 1.6rem;
$fluid-scale: 1.25;

:root {
    --font-size-display: clamp(2.25rem, 1.625rem + 2.5vw, 4.5rem);
    --font-size-h1: clamp(2rem, 1.5rem + 2vw, 4rem);
    --font-size-h2: clamp(1.75rem, 1.375rem + 1.5vw, 3rem);
    --font-size-h3: clamp(1.5rem, 1.25rem + 1vw, 2.5rem);
    --font-size-h4: clamp(1.25rem, 1.125rem + 0.5vw, 2rem);
    --font-size-h5: clamp(1rem, 1rem + 0.25vw, 1.5rem);
    --font-size-h6: clamp(0.875rem, 0.875rem + 0.125vw, 1.25rem);
    --font-size-p: $base-font-size;
}

.heading-display {
    font-size: var(--font-size-display);
}

.heading-1 {
    font-size: var(--font-size-h1);
}

.heading-2 {
    font-size: var(--font-size-h2);
}

.heading-3 {
    font-size: var(--font-size-h3);
}

.heading-4 {
    font-size: var(--font-size-h4);
}

.heading-5 {
    font-size: var(--font-size-h5);
}

.heading-6 {
    font-size: var(--font-size-h6);
}

p {
    font-size: var(--font-size-p);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0 0 1rem 0;
}
h1, .heading-1,
h2, .heading-2,
h3, .heading-3,
h4, .heading-4,
h5, .heading-5,
h6 .heading-6 {
    font-weight: 400;
    line-height: 1;
}
