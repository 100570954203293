@use './variables.scss'  as *;

.global-container {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-left: $spacing-base; 
    padding-right: $spacing-base; 
}

.cta-container {
    background: linear-gradient(176deg, rgba(231,227,221,1) 0%, rgba(250,248,247,1) 100%);
    box-shadow: 
        10px 10px 14px rgba(0, 0, 0, 0.05),
        -10px -10px 14px rgba(255, 255, 255, 0.3);
}